/* You can add global styles to this file, and also import other style files */
.top-2{top:2px}
.logo img {
  max-width: 160px;
}

.otp-input {
  width: 40px !important;
  height: 40px !important;
  font-size: 1rem !important;
  outline: none !important;
}

.ng-otp-input-wrapper.wrapper {
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.otp-input:focus {
  border: 1px solid var(--primary) !important;
}

.success {
  color: #01ac48;
}

.danger {
  color: #ff0606;
}

.align-right {
  float: right;
}

.nav-pills .nav-link {
  color: var(--bodytext);
  text-decoration: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: var(--primary);
  color: var(--bs-black);
}

.modal-header .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-size: 13px;
  outline: none !important;
  z-index: 1;
}

.btn-close:focus {
  box-shadow: none;
}

thead {
  background: #c5c5c5 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #c4c4c4;
}

.main-table th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

td.action-td {
  width: 120px !important;
}

.user-tab.tab-inner {
  border: 1px solid #dfdcdc;
  border-bottom: none;
}

table.table.table-hover {
  margin-bottom: 0;
}

.pagination a {
  text-decoration: none;
  color: var(--bs-black);
}

.pagination a:hover,
.pagination a.active {
  background: var(--primary);
  color: var(--bs-white);
}

.sorting-svg img {
  cursor: pointer;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background: #e7e7e7;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-black);
}

.login label.form-label {
  font-weight: 600;
}

.main-page .page-title {
  padding: 15px 30px;
  margin: -20px -24px 5px !important;
}

.main-page .page-title h2 {
  margin-bottom: 0;
}

.after-none .dropdown-toggle::after {
  display: none !important;
}

td {
  vertical-align: middle;
}

.main-table th:first-child,
.main-table tr td:first-child {
  min-width: 10px;
  width: 90px;
}

option:hover {
  background: var(--primary) !important;
}

.back-arrow-btn {
  font-size: 14px;
  margin-bottom: 15px;
  opacity: 0.8;
  cursor: pointer;
  display: inline-block;
}
.radio__check {
  border-radius: 0 !important;
}
.radio__check:checked[type='radio'] {
  background-image: url(assets/images/checkmark.png);
  background-size: 12px;
}
angular-editor.invalid {
  .angular-editor-textarea {
    border: 1px solid red !important;
  }
}

// .form-switch .form-check-input:focus {
//     background-image: url(assets/images/grey-circle.svg) !important;
// }

@media (min-width: 1200px) {
  .main-page .page-title h2 {
    font-size: 1.75rem !important;
  }
}

@media (max-width: 1024px) {
  .main-table th {
    min-width: 130px;
  }
}

.app-validation-error {
  font-size: 13px;
}
.text-grey-2 {
  color: var(--grey2);
}

.action-buttons:hover .fa-trash-can {
	color: red;
}

.table-actions a:hover {
  color: #eeb519 !important;
}


.tab-content table td,.tab-content table th{white-space:nowrap;}
.text-capitalize {
  text-transform: capitalize
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.tab-content table td,.tab-content table th{white-space:nowrap;}


// country dropdown css
ngx-intl-tel-input  input#phone , ngx-intl-tel-input  {outline: none !important;
  border: none;
}
ngx-intl-tel-input .iti--allow-dropdown .iti__flag-container:hover,
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {background: transparent !important;}
ngx-intl-tel-input .iti__flag-box, ngx-intl-tel-input .iti__country-name { 
  font-size: 0.85rem;
}
ngx-intl-tel-input .iti__dial-code{
  font-size: 0.85rem;
}

ngx-intl-tel-input .search-container input{
   border-bottom:1px solid #ebeaea; 
  font-size: 0.9rem;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: none;
}
ngx-intl-tel-input .iti__selected-flag { 
  padding-left: 0;
}
ngx-intl-tel-input .country-dropdown  {
  border: 1px solid #e7dfdf;
   box-shadow: 0px 0px 12px rgb(0 0 0 / 7%);
}
ngx-intl-tel-input .iti.iti--allow-dropdown.separate-dial-code {width: 100% !important; display: flex;}
ngx-intl-tel-input .iti__selected-flag.dropdown-toggle {
  width: auto !important;
}
 
ngx-intl-tel-input  input#phone {
    padding-left: 10px;
    border-left: 1px solid #e9e3e3 !important;
    border-radius: 0;
} 
ngx-intl-tel-input  .iti__flag-container {
  position: relative;
}
ngx-intl-tel-input.form-control {
  // padding-top: 0;
  // padding-bottom: 0;
  padding-right: 0;
}
 
.iti { 
  display: flex !important;
}

// country dropdown css end


.table-actions  a {color: var(--body-text);}
.table-border,.border-grey {
  border: 1px solid #dfdcdc; 
}
.table-border tr:last-child td{border-bottom: none;}
.doc-auto div#pic-1 img {
  max-height: 100%;
}

table th,table td{white-space: nowrap !important; padding: .5rem .5rem; font-size: 0.9rem !important;}

.tab-content img{animation: none !important;}
.main-table th,.main-table td{font-size: 0.9rem !important;}
.action-td .table-actions {
  justify-content: end;
}
.main-table.table-responsive tr th:last-child > div {
  justify-content: end;
}
.action-td .table-actions > a:last-child{margin-right: 3px !important;}


.description {
  max-width: 290px;
}
.description span {
  white-space: normal !important;
}  

a.btn-sm.btn-primary {
  color: var(--body-text) !important;
}
a.btn-sm.btn-danger {
  color: #ffffff !important;
}

.card .nav-tabs .nav-item.show .nav-link , .card .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #3e3d3a !important;
  border-color: #2f2f2d !important;
  font-weight: 600 !important;
}

.accordion {
    --bs-accordion-btn-active-icon: url(assets/images/tab-downarrow.svg);
}
.accordion-button:not(.collapsed)::after, .accordion-button::after{background-position: center center;}


.card-body.chart canvas {
  margin: 0 auto;
}


.main-page .page-title h2 {
  font-size: 1.6rem !important;
}


@media (min-width: 1200px) and (max-width: 1320px) {
  .main-page .page-title h2 {
    font-size: 1.45rem !important;
  }
  }